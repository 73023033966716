.pagination {
  display: flex;
  align-items: center;
  /* background-color: #fff; */
  color: #383838;
  padding: 20px 40px;
  border-radius: 6px;
  justify-content: center;
}

.pagination__list {
  margin: 20px 8px;
}

.pagination__list .pagination__item {
  display: inline-block;
  margin: 0 8px;
  background-color: #ccc;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 30px;
  cursor: pointer;
}

.btn1,
.btn2 {
  display: inline-block;
  align-items: center;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: #383838;
  background-color: transparent;
  outline: none;
  border: none;
  transition: 0.4s;
}

.btn1:hover {
  /* background-color: #f0f0f0; */
  transform: scale(1.1);
  transition: 0.4s;
}

.btn2:hover {
  /* background-color: #f0f0f0; */
  transform: scale(1.1);
  transition: 0.4s;
}

.pagination__list .pagination__item.active-pagination {
  color: #fff;
  background-image: linear-gradient(#ff4568, #ff4568);
  background-repeat: no-repeat;
}

/* Khi nút bị disabled */
.btn1:disabled,
.btn2:disabled {
  /* background-color: #bdc3c7; */
  cursor: not-allowed;
  opacity: 0.6;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
  .pagination {
    margin-top: 2rem;
    padding: 0;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
}
