.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #868e96; /* Lớp phủ mờ */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Đảm bảo nó nằm trên các phần tử khác */
}

/* Chỉ làm mờ nội dung bên dưới */
.loader-overlay .blurred-content {
  filter: blur(10px); /* Áp dụng hiệu ứng mờ */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    top: 27px;
    left: 27px;
    width: 118px;
    height: 118px;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    top: 86px;
    left: 86px;
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.ldio-yzaezf3dcmj div {
  position: absolute;
  border-width: 14px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-yzaezf3dcmj 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-yzaezf3dcmj div:nth-child(1) {
  border-color: #e90c59;
  animation-delay: 0s;
}
.ldio-yzaezf3dcmj div:nth-child(2) {
  border-color: #46dff0;
  animation-delay: -0.5s;
}
.loadingio-spinner-ripple-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;

  /* background: #ffffff; */
}
.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}

.loading-text-v2 {
  position: absolute;
  margin-top: 200px; /* Khoảng cách giữa spinner và chữ */
  font-size: 18px;

  color: #ffffff;
  font-weight: 800;
}

.loading-text-v2 {
  font-size: 18px;
  color: #fff; /* Màu chữ */
  text-align: center;
  margin-left: 14px;
}

.loading-text-v2 span {
  animation: blink 1.5s infinite;
}

.loading-text-v2 span:nth-child(1) {
  animation-delay: 0s;
}
.loading-text-v2 span:nth-child(2) {
  animation-delay: 0.3s;
}
.loading-text-v2 span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {
  0%,
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
